<template>
  <div>
    <!--begin::Queues-->
    <div v-if="userType === 'admin'" class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <QueuesData />
      </div>
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <QueuesTable />
      </div>
    </div>
    <!--end::Queues-->

    <div v-else class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <Denied></Denied>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import QueuesTable from "@/components/Admins/Settings/System/Queues/Table";
import Denied from "@/components/SYS/errors/Denied";
import QueuesData from "@/components/Admins/Settings/System/Queues/QueuesData";

export default {
  name: "AdminsSettingsQueuesIndex",
  components: {
    QueuesData,
    QueuesTable,
    Denied
  },
  data() {
    return {
      userType: null
    };
  },
  mounted() {
    this.userType = this.$store.getters.userType;
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$t("menu.adminSettings"),
        route: ""
      },
      {
        title: this.$t("menu.adminSettingsSystem"),
        route: ""
      },
      {
        title: this.$t("settings.supervisorManagment"),
        route: "/admin/settings/system/queues"
      }
    ]);
  },
  methods: {}
};
</script>
