import ApiService from "@/core/services/api.service";

ApiService.setHeader();
let url = process.env.VUE_APP_API_ADMIN;

class Languages {
  get(id) {
    return ApiService.get(url, "languages/" + id);
  }
  getAll(params, cancelToken) {
    let options = new URLSearchParams(params).toString();
    let headers = {};
    if (cancelToken) {
      headers.cancelToken = cancelToken;
    }
    return ApiService.get(url, "languages?" + options, headers);
  }
  store(data) {
    return ApiService.post(url + "languages", data);
  }
  update(id, data) {
    return ApiService.put(url + "languages/" + id, data);
  }
  delete(id) {
    return ApiService.delete(url + "languages/" + id);
  }
}

export default new Languages();
