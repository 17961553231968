var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"d-flex flex-row"},[_c('div',{staticClass:"flex-row-auto offcanvas-mobile w-300px w-xl-350px",attrs:{"id":"kt_profile_aside"}},[_c('div',{staticClass:"card card-custom"},[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"card-title"},[_c('h3',{staticClass:"card-label"},[_vm._v(_vm._s(_vm.$t("settings.system")))])])]),_c('div',{staticClass:"card-body pt-4"},[_c('div',{staticClass:"navi navi-bold navi-hover navi-active navi-link-rounded"},_vm._l((_vm.tabNavigation),function(item,i){return _c('div',{key:i,staticClass:"navi-item mb-2"},[_c('router-link',{attrs:{"to":{ name: item.route },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('a',{staticClass:"navi-link py-4",class:{
                    active: isExactActive || _vm.$route.name.includes(item.route)
                  },attrs:{"href":href}},[(item.icon)?_c('span',{staticClass:"navi-icon"},[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1):_vm._e(),_c('span',{staticClass:"navi-text"},[_vm._v(_vm._s(_vm.$t(item.label)))])])]}}],null,true)})],1)}),0)])])]),_c('div',{staticClass:"flex-row-fluid ml-lg-8 client-details"},[(_vm.$route.name === 'adminSettingsSystemLanguages')?_c('Languages'):(_vm.$route.name === 'adminSettingsSystemCountries')?_c('Countries'):(_vm.$route.name === 'adminSettingsSystemSecurity')?_c('LoginSecurity'):(_vm.$route.name === 'adminsSettingsSystemQueues')?_c('Queues'):(_vm.$route.name === 'adminSettingsSystemSecurityWhitelist')?_c('GrantedIpsTable'):(
          [
            'adminSettingsSystemSecurityWhitelistCreate',
            'adminSettingsSystemSecurityWhitelistEdit'
          ].includes(_vm.$route.name)
        )?_c('GrantedIpsEdit'):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }