<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">
          {{ $t("languages.languageCreate") }}
        </h3>
      </div>
      <div class="card-toolbar">
        <button
          class="btn btn-secondary mr-2"
          @click="$emit('cancel-language')"
        >
          {{ $t("general.cancel") }}
        </button>
        <button class="btn btn-primary" @click="storeLanguage">
          {{ $t("general.save") }}
        </button>
      </div>
    </div>
    <v-progress-linear v-if="isBusy" indeterminate color="primary" />
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form">
      <!--begin::Body-->
      <div class="card-body">
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("languages.name") }} (de)
          </label>
          <div class="col-lg-9 col-xl-6">
            <input
              v-model="language.label.de"
              type="text"
              class="form-control"
            />
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("languages.name") }} (en)
          </label>
          <div class="col-lg-9 col-xl-6">
            <input
              v-model="language.label.en"
              type="text"
              class="form-control"
            />
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("languages.iso") }}
          </label>
          <div class="col-lg-9 col-xl-6">
            <input v-model="language.short" type="text" class="form-control" />
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Languages from "@/components/Admins/Settings/System/Languages/languages";

export default {
  data() {
    return {
      language: {
        short: "",
        label: {
          de: "",
          en: ""
        }
      },
      detailsForm: false,
      isBusy: false
    };
  },
  methods: {
    getLanguage() {
      this.isBusy = true;
      let languageId = this.$route.params.id;
      Languages.get(languageId)
        .then(response => {
          this.language = response.data.data;
          this.isBusy = false;
        })
        .catch(error => {
          this.$swal.fire({
            title: this.$t("general.caution"),
            text: error.response.data.message,
            icon: "error"
          });
          this.isBusy = false;
        });
    },
    storeLanguage() {
      this.isBusy = true;
      const data = this.language;
      Languages.store(data)
        .then(() => {
          this.$toast.fire({
            icon: "success",
            title: this.$t("languages.languageCreated")
          });
          this.isBusy = false;
          this.$emit("cancel-language");
        })
        .catch(error => {
          this.$swal.fire({
            title: this.$t("general.caution"),
            text: error.response.data.message,
            icon: "error"
          });
          this.isBusy = false;
        });
    }
  }
};
</script>
