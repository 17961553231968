import ApiService from "@/core/services/api.service";

const url = process.env.VUE_APP_API_ADMIN;

class GrantedIps {
  get(id) {
    return ApiService.get(url, "grant_ips/" + id);
  }

  getAll(params = {}, cancelToken) {
    let options = new URLSearchParams(params).toString();
    let headers = {};
    if (cancelToken) {
      headers.cancelToken = cancelToken;
    }
    return ApiService.get(url, "grant_ips?" + options, headers);
  }

  store(data) {
    return ApiService.post(url + "/grant_ips", data);
  }

  update(userId, data) {
    return ApiService.put(url + "/grant_ips/" + userId, data);
  }

  delete(id) {
    return ApiService.delete(url + "/grant_ips/" + id);
  }
}
export default new GrantedIps();
