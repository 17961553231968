<template>
  <div id="countriesCard" class="card card-custom grid-stack-item-content">
    <div class="card-header" style="cursor: move">
      <div class="card-title">
        <h3 class="card-label">{{ $t("countries.countries") }}</h3>
      </div>
      <div class="card-toolbar">
        <button
          class="btn btn-primary btn-hover"
          @click="$emit('countries-create')"
        >
          <i class="fal fa-plus"></i>
          {{ $t("countries.countryCreate") }}
        </button>
      </div>
    </div>
    <v-progress-linear v-if="isBusy" indeterminate color="primary" />
    <div class="card-body column">
      <TableWrapper :meta="meta" @reload-data="loadCountries">
        <b-table
          responsive
          :items="countries"
          :fields="fields"
          class="dataTable table table-head-custom"
          :show-empty="true"
          :empty-text="$t('table.noRecords')"
        >
          <template #cell(actions)="data">
            <b
              class="btn btn-icon btn-light btn-sm mr-1"
              @click="$emit('countries-edit', data.item.id)"
            >
              <i class="fal fa-pen"></i>
            </b>
          </template>
          <template #cell(eu_member)="data">
            <span
              v-if="data.item.eu_member"
              class="label label-light-success label-inline"
              >{{ $t("general.yes") }}
            </span>
            <span v-else class="label label-light-danger label-inline">
              {{ $t("general.no") }}
            </span>
          </template>
          <template #cell(disable_tax)="data">
            <span
              v-if="data.item.disable_tax"
              class="label label-light-success label-inline"
            >
              {{ $t("general.yes") }}
            </span>
            <span v-else class="label label-light-danger label-inline">
              {{ $t("general.no") }}
            </span>
          </template>
        </b-table>
      </TableWrapper>
    </div>
  </div>
</template>

<script>
import Countries from "@/components/Admins/Settings/System/Countries/countries";
import TableWrapper from "@/components/Tools/TableWrapper";
import { mapGetters } from "vuex";

export default {
  name: "AdminsSettingsSystemCountriesTable",
  components: { TableWrapper },
  data() {
    return {
      countries: [],
      fields: [
        {
          key: "id",
          label: this.$t("table.id"),
          sortable: true
        },
        {
          key: "label_german",
          label: this.$t("countries.name"),
          sortable: true,
          thStyle: { width: "150px" }
        },
        {
          key: "label_international",
          label: this.$t("countries.nameInternational"),
          sortable: true
        },
        {
          key: "iso",
          label: this.$t("countries.iso"),
          sortable: true
        },
        {
          key: "eu_member",
          label: this.$t("countries.euMember"),
          sortable: true
        },
        {
          key: "tax",
          label: this.$t("countries.tax"),
          formatter: value => (value !== null ? value + " %" : ""),
          sortable: true
        },
        {
          key: "disable_tax",
          label: this.$t("countries.disableTax"),
          sortable: true
        },
        {
          key: "actions",
          label: this.$t("table.actions"),
          sortable: false,
          thStyle: { width: "170px" }
        }
      ],
      meta: {},
      isBusy: false
    };
  },
  computed: {
    ...mapGetters("route", ["requestParams", "cancelToken"])
  },
  mounted() {
    this.loadCountries();
    this.$root.$on("countriesTableRefresh", () => {
      this.refreshCard();
    });
  },
  methods: {
    loadCountries() {
      this.isBusy = true;
      let params = this.requestParams();
      let cancelToken = this.cancelToken();
      Countries.getAll(params, cancelToken)
        .then(response => {
          this.countries = response.data.data;
          this.meta = response.data.meta;
          this.isBusy = false;
        })
        .catch(error => {
          this.$error(error);
          this.isBusy = this.axios.isCancel(error);
        });
    },
    deleteCountry(id, label) {
      this.$swal
        .fire({
          icon: "warning",
          title: this.$t("countries.countryDelete"),
          text: this.$t("countries.countryDeleteText", { name: label }),
          confirmButtonText: this.$t("general.delete"),
          showCancelButton: true,
          reverseButtons: true,
          cancelButtonText: this.$t("general.cancel")
        })
        .then(response => {
          response.isConfirmed ? this.deleteCountryAction(id) : "";
        });
    },
    deleteCountryAction(id) {
      this.isBusy = true;
      Countries.delete(id)
        .then(() => {
          this.$toast.fire({
            icon: "success",
            title: this.$t("countries.countryDeleted")
          });
          this.countries = [];
          this.loadCountries();
        })
        .catch(error => {
          this.$swal.fire({
            title: this.$t("general.caution"),
            text: error.response.data.message,
            icon: "error"
          });
          this.isBusy = false;
        });
    }
  }
};
</script>
