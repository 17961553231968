<template>
  <div>
    <div v-for="(ip, key) in ipList" :key="key" class="input-group mb-1">
      <input :value="ip" type="text" class="form-control pr-0" readonly />
      <div class="input-group-append">
        <b class="btn btn-icon btn-light" @click="removeIpFromList(key)">
          <i class="fal fa-trash" />
        </b>
      </div>
    </div>

    <div
      class="input-group"
      :class="ipList && ipList.length > 0 ? 'mt-2' : 'mt-0'"
    >
      <input
        v-model="ip"
        type="text"
        class="form-control pr-0"
        :class="ipValidClass"
        placeholder="IP"
        @input="validateIpInput"
        @keydown.enter="addIpToList"
      />
      <div class="input-group-append" :style="ipValid ? 'cursor: pointer' : ''">
        <b class="btn btn-icon btn-light" @click="addIpToList">
          <i class="fal fa-plus" />
        </b>
      </div>
      <div v-if="tooltipText" class="input-group-append">
        <span v-b-tooltip.top="tooltipText" class="input-group-text">
          <i class="fal fa-circle-question" />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { validateIPAddress } from "@/components/Tools/helperFunctions";

export default {
  name: "MultiIpInput",
  props: {
    value: {
      type: Array,
      default: () => []
    },
    tooltipText: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      ipList: this.value,
      ip: "",
      ipValid: false
    };
  },
  computed: {
    ipValidClass() {
      if (this.ip.length === 0) {
        return "";
      }
      return !this.ipValid ? "is-invalid" : "is-valid";
    }
  },
  watch: {
    value: function () {
      this.ipList = this.value;
    }
  },
  methods: {
    addIpToList() {
      if (!this.ipValid || this.ip.length === 0) {
        return;
      }

      this.ipList.push(this.ip);
      this.ip = "";
      this.ipValid = false;

      this.$emit("input", this.ipList);
    },
    removeIpFromList(key) {
      this.ipList.splice(key, 1);
      this.validateIpInput();
      this.$emit("input", this.ipList);
    },
    validateIpInput() {
      this.ip = this.ip.trim();
      this.ipValid = true;
      if (this.ip.length === 0) {
        this.$emit("changed", false);
        return;
      }
      if (!validateIPAddress(this.ip) && this.ip !== "*") {
        this.ipValid = false;
        this.$emit("changed", false);
        return;
      }
      if (this.ipList.indexOf(this.ip) !== -1) {
        this.ipValid = false;
      }
      this.$emit("changed", this.isValid());
    },
    isValid() {
      return this.ipList.length > 0 || (this.ipValid && this.ip.length > 0);
    },
    getData() {
      if (!this.isValid()) return null;

      if (this.ipList.length > 0) {
        return this.ipList;
      }
      return [this.ip];
    }
  }
};
</script>

<style lang="scss" scoped>
.input-group-append {
  .btn {
    border: 1px solid #e4e6ef;
    min-width: 45px;
  }
}
</style>
