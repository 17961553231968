<template>
  <div>
    <!--begin::Languages-->
    <div v-if="userType === 'admin'" class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <LanguagesTable
          v-if="!showCreate && !showEdit"
          @language-create="showCreateForm"
          @language-edit="showEditForm"
        >
        </LanguagesTable>
        <LanguagesCreate
          v-if="showCreate"
          @cancel-language="cancelLanguage"
        ></LanguagesCreate>
        <LanguagesEdit
          v-if="showEdit"
          :language-id="languageId"
          @cancel-language="cancelLanguage"
        >
        </LanguagesEdit>
      </div>
    </div>
    <!--end::Languages-->

    <div v-if="userType !== 'admin'" class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <Denied></Denied>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import LanguagesTable from "@/components/Admins/Settings/System/Languages/Table";
import LanguagesCreate from "@/components/Admins/Settings/System/Languages/Create";
import LanguagesEdit from "@/components/Admins/Settings/System/Languages/Edit";
import Denied from "@/components/SYS/errors/Denied";

export default {
  name: "AdminsSettingsSystemLanguagesIndex",
  components: {
    LanguagesTable,
    LanguagesCreate,
    LanguagesEdit,
    Denied
  },
  data() {
    return {
      userType: null,
      showEdit: false,
      showCreate: false,
      languageId: null
    };
  },
  mounted() {
    this.userType = this.$store.getters.userType;
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$t("menu.adminSettings"),
        route: ""
      },
      {
        title: this.$t("menu.adminSettingsSystem"),
        route: ""
      },
      {
        title: this.$t("settings.languages"),
        route: "/admin/settings/system/languages"
      },
      {
        title: this.$t("general.overview"),
        route: "/admin/settings/system/languages"
      }
    ]);
  },
  methods: {
    showEditForm(languageId) {
      this.languageId = languageId;
      this.showCreate = false;
      this.showEdit = true;
    },
    showCreateForm() {
      this.showCreate = true;
      this.showEdit = false;
    },
    cancelLanguage() {
      this.showCreate = false;
      this.showEdit = false;
    }
  }
};
</script>
