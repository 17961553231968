<template>
  <div id="languagesCard" class="card card-custom grid-stack-item-content">
    <div class="card-header" style="cursor: move">
      <div class="card-title">
        <h3 class="card-label">{{ $t("languages.languages") }}</h3>
      </div>
      <div class="card-toolbar">
        <a
          href="#"
          class="btn btn-icon btn-sm btn-hover-light-primary ml-1"
          data-card-tool="reload"
          data-toggle="tooltip"
          data-placement="top"
          :title="$t('general.reload')"
          @click.prevent="refreshCard($event)"
        >
          <i class="fal fa-arrows-rotate"></i>
        </a>
        <a
          href="#"
          class="btn btn-icon btn-sm btn-hover-light-primary ml-1 mr-1"
          data-card-tool="remove"
          data-toggle="tooltip"
          data-placement="top"
          :title="$t('general.remove')"
          @click.prevent="closeCard($event)"
        >
          <i class="fal fa-xmark"></i>
        </a>
        <button
          class="btn btn-primary btn-hover"
          @click="$emit('language-create')"
        >
          <i class="fal fa-plus"></i>
          {{ $t("languages.languageCreate") }}
        </button>
      </div>
    </div>
    <v-progress-linear v-if="isBusy" indeterminate color="primary" />
    <div class="card-body column">
      <TableWrapper :meta="meta" @reload-data="loadLanguages">
        <b-table
          responsive
          :items="languages"
          :fields="fields"
          class="dataTable table table-head-custom"
          :show-empty="true"
          :empty-text="$t('table.noRecords')"
        >
          <template #cell(actions)="data">
            <b
              class="btn btn-icon btn-light btn-sm mr-1"
              @click="$emit('language-edit', data.item.id)"
            >
              <i class="fal fa-pen"></i>
            </b>
          </template>
        </b-table>
      </TableWrapper>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import Languages from "@/components/Admins/Settings/System/Languages/languages";
import TableWrapper from "@/components/Tools/TableWrapper";
import { mapGetters } from "vuex";

export default {
  name: "AdminsSettingsSystemLanguagesTable",
  components: { TableWrapper },
  data() {
    return {
      languages: [],
      fields: [
        {
          key: "id",
          label: this.$t("table.id"),
          sortable: true,
          thStyle: { width: "70px" }
        },
        {
          key: "label",
          label: this.$t("languages.name"),
          sortable: true,
          thStyle: { width: "150px" }
        },
        {
          key: "short",
          label: this.$t("languages.iso"),
          sortable: true,
          thStyle: { width: "0px" }
        },
        {
          key: "actions",
          label: this.$t("table.actions"),
          sortable: false,
          thStyle: { width: "170px" }
        }
      ],
      meta: {},
      isBusy: false
    };
  },
  computed: {
    ...mapGetters("route", ["requestParams", "cancelToken"])
  },
  mounted() {
    this.loadLanguages();
    this.$root.$on("languagesTableRefresh", () => {
      this.refreshCard();
    });
  },
  methods: {
    loadLanguages() {
      this.isBusy = true;
      let params = this.requestParams();
      let cancelToken = this.cancelToken();
      Languages.getAll(params, cancelToken)
        .then(response => {
          this.meta = response.data.meta;
          this.languages = response.data.data;
          this.isBusy = false;
        })
        .catch(error => {
          this.$error(error);
          this.isBusy = this.axios.isCancel(error);
        });
    },
    closeCard() {
      $("#languagesCard").fadeOut(300, function () {
        $("#languagesCard").parent().remove();
      });
      this.$destroy();
    },
    refreshCard() {
      this.loadLanguages();
    },
    showLanguage(id) {
      this.$router.push({
        name: "adminSettingsSystemLanguagesDetails",
        params: { id: id }
      });
    },
    deleteLanguage(id, label) {
      this.$swal
        .fire({
          icon: "warning",
          title: this.$t("languages.languageDelete"),
          text: this.$t("languages.languageDeleteText", { name: label }),
          confirmButtonText: this.$t("general.delete"),
          showCancelButton: true,
          reverseButtons: true,
          cancelButtonText: this.$t("general.cancel")
        })
        .then(response => {
          response.isConfirmed ? this.deleteLanguageAction(id) : "";
        });
    },
    deleteLanguageAction(id) {
      this.isBusy = true;
      Languages.delete(id)
        .then(() => {
          this.$toast.fire({
            icon: "success",
            title: this.$t("languages.languageDeleted")
          });
          this.languages = [];
          this.loadLanguages();
        })
        .catch(error => {
          this.$swal.fire({
            title: this.$t("general.caution"),
            text: error.response.data.message,
            icon: "error"
          });
          this.isBusy = false;
        });
    }
  }
};
</script>

<style lang="css">
.table td {
  vertical-align: middle;
}
</style>
