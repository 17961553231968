<template>
  <div id="queuesCard" class="card card-custom grid-stack-item-content">
    <div class="card-header" style="cursor: move">
      <div class="card-title">
        <h3 class="card-label">{{ $t("settings.supervisorManagment") }}</h3>
      </div>
      <div class="card-toolbar">
        <button
          class="btn btn-primary btn-hover mr-1"
          @click.prevent="supervisorAction('recreateConfig')"
        >
          <i class="fal fa-gear"></i>
          {{ $t("queues.supervisorRecreateConfig") }}
        </button>
        <button
          class="btn btn-primary btn-hover mr-1"
          @click.prevent="supervisorAction('start')"
        >
          <i class="fal fa-play"></i>
          {{ $t("queues.supervisorStart") }}
        </button>
        <button
          class="btn btn-primary btn-hover mr-1"
          @click.prevent="supervisorAction('restart')"
        >
          <i class="fal fa-undo"></i>
          {{ $t("queues.supervisorRestart") }}
        </button>
        <button
          class="btn btn-primary btn-hover"
          @click.prevent="supervisorAction('stop')"
        >
          <i class="fal fa-stop"></i>
          {{ $t("queues.supervisorStop") }}
        </button>
      </div>
    </div>
    <v-progress-linear v-if="isBusy" indeterminate color="primary" />
    <div class="card-body column">
      <div class="row justify-content-between align-items-center mb-3">
        <!--begin::Pagination-->
        <div class="col-auto d-flex align-items-center">
          <b-form-select
            v-model="pageSize"
            :options="pageSizes"
            class="mr-3 shadow-none"
            value-field="item"
            text-field="name"
            size="sm"
            disabled-field="notEnabled"
            @change="handlePageSizeChange"
          ></b-form-select>
          <span class="text-muted text-no-wrap">
            {{ $t("general.recordsPerPage") }}
          </span>
        </div>
        <div class="col-auto">
          <b-input
            v-model="searchTitle"
            :placeholder="$t('search.searchTerm')"
          />
        </div>
        <!--end:: Pagination-->
      </div>
      <div class="dataTables_wrapper">
        <b-table
          responsive
          :items="queues"
          :fields="fields"
          class="dataTable table table-head-custom"
          :filter="searchTitle"
          :show-empty="true"
          :empty-text="$t('table.noRecords')"
        >
          <template #cell(output)="data">
            <span v-if="data.item.output">
              <div class="p-3 mr-1">
                <div v-if="typeof data.item.output === 'object'">
                  <pre>{{ JSON.stringify(data.item.output, null, 2) }}</pre>
                </div>
                <div v-else>
                  {{ data.item.output }}
                </div>
              </div>
            </span>
          </template>
          <template #cell(time)="data">
            <span v-if="data.item.time">
              <span>
                {{ formatDateAssigned(data.item.time) }}
              </span>
            </span>
          </template>
        </b-table>
      </div>
      <div class="row justify-content-between align-items-center mt-3">
        <div class="col-auto">
          <span v-if="Object.keys(meta).length" class="text-muted text-no-wrap">
            {{
              $t("general.records", {
                from: meta.from,
                to: meta.to,
                total: meta.total
              })
            }}
          </span>
        </div>

        <div class="col-auto d-flex flex-wrap">
          <b-pagination
            v-model="page"
            :total-rows="totalRecords"
            :per-page="pageSize"
            size="md"
            class="pl-0 mb-0"
            @change="handlePageChange"
          ></b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDate } from "@/components/Tools/modifiers";
import Queues from "@/components/Admins/Settings/System/Queues/queues";
import Swal from "sweetalert2";

export default {
  name: "AdminsSettingsSystemQueuesTable",
  data() {
    return {
      fields: [
        {
          key: "time",
          label: this.$t("queues.time"),
          sortable: true,
          thStyle: { width: "100px" }
        },
        {
          key: "command",
          label: this.$t("queues.command"),
          sortable: false,
          thStyle: { width: "100px" }
        },
        {
          key: "output",
          label: this.$t("queues.output"),
          sortable: true,
          thStyle: { width: "350px" }
        }
      ],
      queues: [],
      //meta
      meta: {},
      searchTitle: "",
      page: 1,
      totalPages: 10,
      pageSize: 10,
      pageSizes: [10, 20, 50, 100],
      totalRecords: 0,
      isBusy: false
    };
  },
  mounted() {},
  methods: {
    formatDateAssigned(value) {
      return formatDate(value);
    },
    getRequestParams(page, pageSize) {
      let params = {};
      if (page) {
        params["page"] = page;
      }
      if (pageSize) {
        params["size"] = pageSize;
      }
      return params;
    },
    setMeta(meta) {
      this.meta = meta;
      this.totalPages = meta.last_page;
      this.totalRecords = meta.total;
    },
    handlePageChange(value) {
      this.page = value;
      this.loadSupervisorAction();
    },
    handlePageSizeChange(size) {
      this.pageSize = size;
      this.page = 1;
      this.loadSupervisorAction();
    },
    loadSupervisorAction(supervisor) {
      this.isBusy = true;
      const params = this.getRequestParams(this.page, this.pageSize);
      let resp;
      let successTxt;
      switch (supervisor) {
        case "recreateConfig":
          resp = Queues.recreateConfig(params);
          successTxt = this.$t("queues.supervisorRecreateConfigSuccess");
          break;
        case "start":
          resp = Queues.start(params);
          successTxt = this.$t("queues.supervisorStartSuccess");
          break;
        case "restart":
          resp = Queues.restart(params);
          successTxt = this.$t("queues.supervisorRestartSuccess");
          break;
        case "stop":
          resp = Queues.stop(params);
          successTxt = this.$t("queues.supervisorStopSuccess");
          break;
      }
      resp
        .then(response => {
          const queues = response.data.data;
          this.queues = queues.sort().reverse();
          this.isBusy = false;
          this.$toast.fire({
            title: successTxt,
            icon: "success"
          });
        })
        .catch(error => {
          this.$swal.fire({
            title: this.$t("general.caution"),
            text: error.response.data.message,
            icon: "error"
          });
          this.isBusy = false;
        });
    },
    supervisorAction(action) {
      let confirmText;
      switch (action) {
        case "recreateConfig":
          confirmText = this.$t("queues.supervisorRecreateConfig");
          break;
        case "start":
          confirmText = this.$t("queues.supervisorStart");
          break;
        case "restart":
          confirmText = this.$t("queues.supervisorRestart");
          break;
        case "stop":
          confirmText = this.$t("queues.supervisorStop");
          break;
      }
      Swal.fire({
        title: this.$t("queues.confirm", { action: '"' + confirmText + '"' }),
        icon: "warning",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: "#3085d6",
        showCloseButton: true,
        cancelButtonText: this.$t("general.cancel"),
        confirmButtonText: this.$t("general.confirm")
      }).then(result => {
        if (result.isConfirmed) {
          this.loadSupervisorAction(action);
        }
      });
    }
  }
};
</script>

<style lang="css">
.table td {
  vertical-align: middle;
}
</style>
