import Vue from "vue";
import i18n from "@/core/plugins/vue-i18n";

export function generateHash(length = 20, prefix = "") {
  let chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
  let result = "";
  for (let i = length - prefix.length; i > 0; --i)
    result += chars[Math.floor(Math.random() * chars.length)];
  return prefix + result;
}

export function isJson(item) {
  item = typeof item !== "string" ? JSON.stringify(item) : item;

  try {
    item = JSON.parse(item);
  } catch (e) {
    return false;
  }

  return typeof item === "object" && item !== null;
}

export function cloneDeep(obj) {
  return JSON.parse(JSON.stringify(obj));
}

export function validateIPAddress(ipaddress) {
  return /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
    ipaddress
  );
}

export function copyToClipboard(variable, prefix = "", suffix = "") {
  navigator.clipboard.writeText(prefix + variable + suffix);
  Vue.prototype.$toast.fire({
    icon: "info",
    title: i18n.t("general.copied")
  });
}
