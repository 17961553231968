<template>
  <div id="grantedIpsCard" class="card card-custom grid-stack-item-content">
    <div class="card-header">
      <div class="card-title">
        <h3 class="card-label">{{ $t("grantedIps.grantedIps") }}</h3>
      </div>
      <div class="card-toolbar">
        <button
          class="btn btn-secondary mr-2"
          @click="$router.push({ name: 'adminSettingsSystemSecurity' })"
        >
          {{ $t("general.previous") }}
        </button>
        <button class="btn btn-primary btn-hover" @click.prevent="showCreate">
          <i class="fal fa-plus"></i>
          {{ $t("grantedIps.addNewEntry") }}
        </button>
      </div>
    </div>
    <v-progress-linear v-if="isBusy" indeterminate color="primary" />
    <div class="card-body column">
      <TableWrapper
        :meta="meta"
        :disable-search="true"
        @reload-data="getGrantedIps"
      >
        <b-table
          responsive
          :items="grantedIps"
          :fields="fields"
          class="dataTable table table-head-custom"
          :show-empty="true"
          :empty-text="$t('table.noRecords')"
        >
          <template #cell(user_id)="data">
            <div>
              <span v-if="data.item.user">#{{ data.item.user_id }} - </span>
              <span v-if="data.item.user">{{ data.item.user.name }}</span>
              <span v-if="!data.item.public && !data.item.user_id">{{
                $t("admin.allUsers")
              }}</span>
            </div>
          </template>
          <template #cell(ips)="data">
            <span v-if="data.item.ips">
              {{ data.item.ips.join(", ") }}
            </span>
          </template>
          <template #cell(actions)="data">
            <b
              class="btn btn-icon btn-light btn-sm mr-1"
              @click="showDetails(data.item.id)"
            >
              <i class="fal fa-pen"></i>
            </b>
            <b
              class="btn btn-icon btn-light btn-sm mr-1"
              @click="deleteGrantedIp(data.item.id)"
            >
              <i class="fal fa-trash"></i>
            </b>
          </template>
          <template #cell(active)="data">
            <span
              v-if="data.item.active"
              class="label label-lg label-light-success label-inline"
            >
              {{ $t("general.active") }}
            </span>
            <span v-else class="label label-lg label-light-danger label-inline">
              {{ $t("general.inactive") }}
            </span>
          </template>
        </b-table>
      </TableWrapper>
    </div>
  </div>
</template>

<script>
import GrantedIps from "@/components/Admins/Settings/System/Security/GrantedIps/grantedIps";
import { formatDate } from "@/components/Tools/modifiers";
import Swal from "sweetalert2";
import TableWrapper from "@/components/Tools/TableWrapper";
import { mapGetters } from "vuex";

export default {
  components: { TableWrapper },
  data() {
    return {
      grantedIps: [],
      fields: [
        {
          key: "id",
          label: this.$t("table.id"),
          sortable: true
        },
        {
          key: "user_id",
          label: this.$t("grantedIps.user"),
          sortable: true
        },
        {
          key: "route",
          label: this.$t("grantedIps.route"),
          sortable: true
        },
        {
          key: "ips",
          label: this.$t("grantedIps.ipAddresses"),
          sortable: true
        },
        {
          key: "description",
          label: this.$t("grantedIps.description"),
          sortable: true
        },
        {
          key: "active",
          label: this.$t("table.status"),
          sortable: true
        },
        {
          key: "updated_at",
          formatter: "formatDateAssigned",
          label: this.$t("table.updatedAt"),
          sortable: true
        },
        {
          key: "actions",
          label: this.$t("table.actions"),
          sortable: false
        }
      ],
      // Meta info
      meta: {},
      isBusy: false
    };
  },
  computed: {
    ...mapGetters("route", ["requestParams", "cancelToken"])
  },
  mounted() {
    this.getGrantedIps();
  },
  methods: {
    getGrantedIps() {
      this.isBusy = true;
      let params = this.requestParams();
      let cancelToken = this.cancelToken();
      GrantedIps.getAll(params, cancelToken)
        .then(response => {
          this.grantedIps = response.data.data;
          this.meta = response.data.meta;
          this.isBusy = false;
        })
        .catch(error => {
          this.$error(error);
          this.isBusy = this.axios.isCancel(error);
        });
    },
    formatDateAssigned(value) {
      return formatDate(value);
    },
    showDetails(id) {
      this.$router.push({
        name: "adminSettingsSystemSecurityWhitelistEdit",
        params: { id: id }
      });
    },
    showCreate() {
      this.$router.push({ name: "adminSettingsSystemSecurityWhitelistCreate" });
    },
    deleteGrantedIp(id) {
      let messageText = this.$t("grantedIps.confirmDeleteText");

      Swal.fire({
        title: this.$t("grantedIps.confirmDeleteTitle"),
        text: messageText,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("general.cancel"),
        confirmButtonText: this.$t("general.delete")
      }).then(result => {
        if (result.isConfirmed) {
          GrantedIps.delete(id)
            .then(() => {
              this.$toast.fire({
                icon: "success",
                title: this.$t("grantedIps.deleteSuccessText")
              });
              if (this.grantedIps.length === 1 && this.page !== 1) {
                this.handlePageChange(this.page - 1);
              } else {
                this.getGrantedIps();
              }
              this.isBusy = false;
            })
            .catch(error => {
              Swal.fire("Error", error.response.data.message, "error");
            });
        }
      });
    }
  }
};
</script>
