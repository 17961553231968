<template>
  <div class="row">
    <div class="col-lg-6 col-12">
      <div class="card card-custom">
        <div class="card-header">
          <div class="card-title">
            <h3 class="card-label">{{ $t("settings.supervisorRamAll") }}</h3>
          </div>
        </div>
        <v-progress-linear v-if="isBusy" indeterminate color="primary" />
        <div class="card-body">
          <div class="row align-items-center">
            <div class="col-lg-8 col-6">
              {{ $t("settings.supervisorActiveTenantsActiveQueues") }}
            </div>
            <div class="col-lg-4 col-6">
              {{ formatRAM(queuesData.activeTenantsActiveQueues) }}
            </div>
          </div>
          <div class="row align-items-center">
            <div class="col-lg-8 col-6">
              {{ $t("settings.supervisorAllTenantsActiveQueues") }}
            </div>
            <div class="col-lg-4 col-6">
              {{ formatRAM(queuesData.allTenantsActiveQueues) }}
            </div>
          </div>
          <div class="row align-items-center">
            <div class="col-lg-8 col-6">
              {{ $t("settings.supervisorActiveTenantsAllQueues") }}
            </div>
            <div class="col-lg-4 col-6">
              {{ formatRAM(queuesData.activeTenantsAllQueues) }}
            </div>
          </div>
          <div class="row align-items-center">
            <div class="col-lg-8 col-6">
              {{ $t("settings.supervisorAllTenantsAllQueues") }}
            </div>
            <div class="col-lg-4 col-6">
              {{ formatRAM(queuesData.allTenantsAllQueues) }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6 col-12">
      <div class="card card-custom">
        <div class="card-header">
          <div class="card-title">
            <h3 class="card-label">
              {{ $t("settings.supervisorRamTenants") }}
            </h3>
          </div>
        </div>
        <v-progress-linear v-if="isBusy" indeterminate color="primary" />
        <div class="card-body">
          <b-table
            responsive
            :items="queuesData.tenants"
            :fields="fields"
            class="dataTable table table-head-custom"
            :show-empty="true"
            :empty-text="$t('table.noRecords')"
          >
            <template #cell(actions)="data">
              <b
                class="btn btn-icon btn-light btn-sm mr-1"
                @click="$emit('language-edit', data.item.id)"
              >
                <i class="fal fa-pen"></i>
              </b>
            </template>
          </b-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Queues from "@/components/Admins/Settings/System/Queues/queues";

export default {
  components: {},
  props: {},
  data() {
    return {
      queuesData: {
        activeTenantsActiveQueues: 0,
        activeTenantsAllQueues: 0,
        allTenantsActiveQueues: 0,
        allTenantsAllQueues: 0,
        tenants: []
      },
      fields: [
        {
          key: "name",
          label: this.$t("table.name"),
          sortable: true
        },
        {
          key: "activeQueueMemory",
          label: this.$t("settings.supervisorActiveQueueMemory"),
          formatter: this.formatRAM,
          sortable: true
        },
        {
          key: "totalQueueMemory",
          label: this.$t("settings.supervisorTotalQueueMemory"),
          formatter: this.formatRAM,
          sortable: true
        }
      ],
      isBusy: false
    };
  },
  computed: {},
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.isBusy = true;
      Queues.data()
        .then(response => {
          this.queuesData = response.data.data;
          this.isBusy = false;
        })
        .catch(error => {
          this.$error(error);
        });
    },
    formatRAM(value) {
      let unit = "MB";
      if (value > 1024) {
        value = value / 1000;
        unit = "GB";
      }
      value = Math.round(value * 100) / 100;
      return value.toLocaleString("de-DE") + " " + unit;
    }
  }
};
</script>
