<template>
  <div id="loginSecurityCard" class="card card-custom grid-stack-item-content">
    <div class="card-header" style="cursor: move">
      <div class="card-title">
        <h3 class="card-label">{{ $t("settings.loginSecurity") }}</h3>
      </div>
      <div class="card-toolbar">
        <button class="btn btn-primary" @click="save">
          {{ $t("general.save") }}
        </button>
      </div>
    </div>
    <v-progress-linear v-if="isBusy" indeterminate color="primary" />
    <form class="form">
      <div class="card-body">
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("settings.securityMaxAttempts") }}
          </label>
          <div class="col-md-9 col-lg-6 col-xl-4">
            <div class="input-group">
              <input
                v-model="securitySettings.maxAttempts"
                type="text"
                class="form-control"
              />
            </div>
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("settings.securityLockMinutesAfterMaxAttempts") }}
          </label>
          <div class="col-md-9 col-lg-6 col-xl-4">
            <div class="input-group">
              <input
                v-model="securitySettings.lockMinutesAfterMaxAttempts"
                type="text"
                class="form-control"
              />
            </div>
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("settings.securityMaxAttemptsBeforeLock") }}
          </label>
          <div class="col-md-9 col-lg-6 col-xl-4">
            <div class="input-group">
              <input
                v-model="securitySettings.maxAttemptsBeforeLock"
                type="text"
                class="form-control"
              />
            </div>
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("settings.securityCheckIp") }}
          </label>
          <div class="col-md-4 col-lg-3 col-xl-2">
            <span class="switch switch-sm">
              <label>
                <input v-model="securitySettings.checkIP" type="checkbox" />
                <span></span>
              </label>
            </span>
          </div>
          <div class="col-md-5 col-lg-3 col-xl-2">
            <button
              class="btn btn-primary position-absolute"
              style="right: 0; top: 0"
              @click="
                $router.push({ name: 'adminSettingsSystemSecurityWhitelist' })
              "
            >
              {{ $t("settings.editIps") }}
            </button>
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("settings.securityTwoFaCheck") }}
          </label>
          <div class="col-md-9 col-lg-6 col-xl-4">
            <span class="switch switch-sm">
              <label>
                <input v-model="securitySettings.twoFaCheck" type="checkbox" />
                <span></span>
              </label>
            </span>
          </div>
        </div>
        <div
          v-show="securitySettings.twoFaCheck"
          class="form-group row align-items-center"
        >
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("settings.securityTwoFaCodeExpireAfterMinutes") }}
          </label>
          <div class="col-md-9 col-lg-6 col-xl-4">
            <div class="input-group">
              <input
                v-model="securitySettings.twoFaCodeExpireAfterMinutes"
                type="text"
                class="form-control"
              />
            </div>
          </div>
        </div>
        <div
          v-show="securitySettings.twoFaCheck"
          class="form-group row align-items-center"
        >
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("settings.twoFaDeviceExpireAfterDays") }}
          </label>
          <div class="col-md-9 col-lg-6 col-xl-4">
            <div class="input-group">
              <input
                v-model="securitySettings.twoFaDeviceExpireAfterDays"
                type="text"
                class="form-control"
              />
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import AdminSettings from "@/components/Admins/Settings/System/settings";
import Swal from "sweetalert2";
import GrantedIps from "@/components/Admins/Settings/System/Security/GrantedIps/grantedIps";

export default {
  name: "AdminsSettingsSystemSecurityLoginSecurity",
  data() {
    return {
      isBusy: false,
      securitySettings: {
        maxAttempts: 3,
        maxAttemptsBeforeLock: 5,
        lockMinutesAfterMaxAttempts: 5,
        twoFaCheck: false,
        twoFaCodeExpireAfterMinutes: 10,
        twoFaDeviceExpireAfterDays: 30,
        checkIP: false
      },
      settings: {},
      currentIp: "",
      grantedIps: []
    };
  },
  mounted() {
    this.getSettings();
    this.loadGrantedIps();
    this.getCurrentIp();
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$t("menu.adminSettings"),
        route: ""
      },
      {
        title: this.$t("menu.adminSettingsSystem"),
        route: ""
      },
      {
        title: this.$t("settings.loginSecurity"),
        route: "/admin/settings/system/security"
      }
    ]);
  },
  methods: {
    getSettings() {
      this.isBusy = true;
      AdminSettings.getByName("loginSecurity")
        .then(response => {
          this.settings = response.data.data;
          this.securitySettings = this.settings.value;
          this.isBusy = false;
        })
        .catch(error => {
          Swal.fire({
            title: this.$t("general.caution"),
            text: error.response.data.message,
            icon: "error"
          });
          this.isBusy = false;
        });
    },
    loadGrantedIps() {
      GrantedIps.getAll().then(response => {
        this.grantedIps = response.data.data;
      });
    },
    getCurrentIp() {
      fetch("https://api.ipify.org?format=json")
        .then(x => x.json())
        .then(({ ip }) => {
          this.currentIp = ip;
        });
    },
    save(checkIp = true) {
      if (checkIp && !this.checkIpGranted()) return;
      this.isBusy = true;
      this.settings.value = this.securitySettings;
      AdminSettings.update(this.settings.id, this.settings)
        .then(() => {
          this.$toast.fire({
            icon: "success",
            title: this.$t("settings.settingUpdated")
          });
          this.getSettings();
          this.isBusy = false;
        })
        .catch(error => {
          Swal.fire({
            title: this.$t("general.caution"),
            text: error.response.data.message,
            icon: "error"
          });
          this.isBusy = false;
        });
    },
    checkIpGranted() {
      if (!this.securitySettings.checkIP || this.currentIp === "") {
        return true;
      }

      if (this.grantedIps.length === 0) {
        Swal.fire({
          title: this.$t("grantedIps.saveTitle"),
          text: this.$t("grantedIps.grantedIpsNeededToSave"),
          icon: "info",
          showCancelButton: true,
          showConfirmButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: this.$t("general.close")
        });
        this.getSettings();
        return false;
      }

      for (const grantedIp of this.grantedIps) {
        if (grantedIp.ips.includes(this.currentIp)) {
          return true;
        }
      }

      Swal.fire({
        title: this.$t("grantedIps.saveTitle"),
        text: this.$t("grantedIps.saveWarning"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("general.cancel"),
        confirmButtonText: this.$t("general.save")
      }).then(result => {
        if (result.isConfirmed) {
          this.save(false);
          return;
        }
        this.getSettings();
      });

      return false;
    }
  }
};
</script>
