import ApiService from "@/core/services/api.service";
import store from "@/core/services/store";
var url = process.env.VUE_APP_API_ADMIN;
ApiService.setHeader("x-api-key", store.getters.apiToken);
if (store.getters.apiTokenPartner) {
  ApiService.setHeader("x-partner-key", store.getters.apiTokenPartner);
}
class Settings {
  get(id) {
    return ApiService.get(url, "settings/" + id);
  }
  getAll(params) {
    var options = "?page=" + params.page + "&perPage=" + params.size;
    return ApiService.get(url, "settings" + options);
  }
  getByName(name) {
    return ApiService.get(url, "settings/getByName/" + name);
  }
  store(data) {
    return ApiService.post(url + "/settings", data);
  }
  async update(id, data) {
    return ApiService.put(url + "/settings/" + id, data);
  }
  delete(id) {
    return ApiService.delete(url + "/settings/" + id);
  }
}
export default new Settings();
