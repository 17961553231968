import ApiService from "@/core/services/api.service";
import store from "@/core/services/store";

const url = process.env.VUE_APP_API_ADMIN;
const urlTenant = process.env.VUE_APP_API_TENANT;

ApiService.setHeader();
class Admins {
  get(id) {
    if (store.getters.userType === "admin") {
      return ApiService.get(url, "users/" + id);
    }
    return ApiService.get(urlTenant, "users/" + id);
  }
  getAll(params, cancelToken = undefined) {
    let options = new URLSearchParams(params).toString();
    let headers = {};
    if (cancelToken) {
      headers.cancelToken = cancelToken;
    }
    if (store.getters.userType === "admin") {
      return ApiService.get(url, "users?" + options, headers);
    }
    // get users from main database as tenant
    return ApiService.get(urlTenant, "contact_persons?" + options, headers);
  }
  store(data) {
    return ApiService.post(url + "/users", data);
  }
  update(userId, data) {
    return ApiService.put(url + "/users/" + userId, data);
  }
  delete(id) {
    return ApiService.delete(url + "/users/" + id);
  }
}
export default new Admins();
