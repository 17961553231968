<template>
  <div>
    <!--begin::Countries-->
    <div v-if="userType === 'admin'" class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <CountriesTable
          v-if="!showCreate && !showEdit"
          @countries-create="showCreateForm"
          @countries-edit="showEditForm"
        ></CountriesTable>
        <CountriesCreate
          v-if="showCreate"
          @cancel-countries="cancelCountries"
        ></CountriesCreate>
        <CountriesEdit
          v-if="showEdit"
          :country-id="countryId"
          @cancel-countries="cancelCountries"
        ></CountriesEdit>
      </div>
    </div>
    <!--end::Countries-->
    <div v-if="userType !== 'admin'" class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <Denied></Denied>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import CountriesTable from "@/components/Admins/Settings/System/Countries/Table";
import CountriesCreate from "@/components/Admins/Settings/System/Countries/Create";
import CountriesEdit from "@/components/Admins/Settings/System/Countries/Edit";
import Denied from "@/components/SYS/errors/Denied";

export default {
  name: "AdminsSettingsSystemCountriesIndex",
  components: {
    CountriesTable,
    CountriesCreate,
    CountriesEdit,
    Denied
  },
  data() {
    return {
      userType: null,
      showEdit: false,
      showCreate: false,
      countryId: null
    };
  },
  mounted() {
    this.userType = this.$store.getters.userType;

    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$t("menu.adminSettings"),
        route: ""
      },
      {
        title: this.$t("menu.adminSettingsSystem"),
        route: ""
      },
      {
        title: this.$t("settings.countries"),
        route: "/admin/settings/system/countries"
      },
      {
        title: this.$t("general.overview"),
        route: "/admin/settings/system/countries"
      }
    ]);
  },
  methods: {
    showEditForm(countryId) {
      this.countryId = countryId;
      this.showCreate = false;
      this.showEdit = true;
    },
    showCreateForm() {
      this.showCreate = true;
      this.showEdit = false;
    },
    cancelCountries() {
      this.showCreate = false;
      this.showEdit = false;
    }
  }
};
</script>
