import ApiService from "@/core/services/api.service";
import store from "@/core/services/store";

ApiService.setHeader();
let url = process.env.VUE_APP_API_ADMIN;
let urlTenant = process.env.VUE_APP_API_TENANT;

class Countries {
  get(id) {
    return ApiService.get(url, "countries/" + id);
  }
  getAll(params, cancelToken) {
    let query = new URLSearchParams(params).toString();
    let headers = {};
    if (cancelToken) {
      headers.cancelToken = cancelToken;
    }
    if (store.getters.userType === "admin") {
      return ApiService.get(url, "countries?" + query, headers);
    }
    return ApiService.get(urlTenant, "countries?" + query, headers);
  }
  store(data) {
    return ApiService.post(url + "/countries", data);
  }
  update(id, data) {
    return ApiService.put(url + "/countries/" + id, data);
  }
  delete(id) {
    return ApiService.delete(url + "/countries/" + id);
  }
}

export default new Countries();
