<template>
  <div class="card card-custom">
    <div class="card-header py-3">
      <div class="card-title d-flex align-items-center">
        <h3 v-if="edit" class="card-label font-weight-bolder text-dark">
          {{ $t("grantedIps.updateEntry") }}
        </h3>
        <h3 v-else class="card-label font-weight-bolder text-dark">
          {{ $t("grantedIps.addNewEntry") }}
        </h3>
      </div>
      <div class="card-toolbar">
        <button class="btn btn-secondary mr-2" @click="cancel">
          {{ $t("general.cancel") }}
        </button>
        <button
          :class="saveButtonEnabled ? 'btn-primary' : 'btn-secondary'"
          class="btn"
          :disabled="isBusy"
          @click="save"
        >
          {{ $t("general.save") }}
        </button>
      </div>
    </div>

    <v-progress-linear v-if="isBusy" indeterminate color="primary" />

    <div class="card-body column">
      <div class="form-group row align-items-top">
        <label class="col-xl-2 col-lg-2 col-md-3 col-form-label">
          {{ $t("grantedIps.ipAddresses") }}
        </label>
        <div class="col-md-9 col-lg-10 col-xl-9">
          <div class="input-group">
            <MultiIpInput
              ref="ipInput"
              v-model="data.ips"
              :tooltip-text="$t('grantedIps.ipAddressesToolTip')"
              style="width: 100%"
              @changed="validateFields"
            />
            <span v-if="!ipsValid" class="text-invalid">
              {{ $t("validation.required.ipAddresses") }}
            </span>
          </div>
        </div>
      </div>

      <div class="form-group row align-items-center">
        <label class="col-xl-2 col-lg-2 col-md-3 col-form-label">
          {{ $t("grantedIps.public") }}
        </label>
        <div class="col-md-9 col-lg-10 col-xl-9">
          <span
            v-b-tooltip.right="$t('grantedIps.publicTooltip')"
            class="switch switch-sm"
          >
            <label>
              <input v-model="data.public" type="checkbox" />
              <span></span>
            </label>
          </span>
        </div>
      </div>

      <div class="form-group row align-items-center">
        <label class="col-xl-2 col-lg-2 col-md-3 col-form-label">
          {{ $t("grantedIps.user") }}
        </label>
        <div class="col-md-9 col-lg-10 col-xl-9">
          <div class="input-group">
            <v-select
              v-model="data.user_id"
              class="form-control"
              :class="!$v.data.user_id.required ? 'is-invalid' : 'is-valid'"
              :items="users"
              item-text="name"
              item-value="id"
              :menu-props="{ offsetY: true }"
              :disabled="data.public"
              @change="validateFields"
            />
            <div class="input-group-append">
              <span
                v-b-tooltip.top="$t('grantedIps.userTooltip')"
                class="input-group-text"
              >
                <i class="fal fa-circle-question" />
              </span>
            </div>
            <b-form-invalid-feedback
              v-if="!$v.data.user_id.required"
              id="userid-feedback"
            >
              {{ $t("validation.required.user") }}
            </b-form-invalid-feedback>
          </div>
        </div>
      </div>

      <div class="form-group row align-items-center">
        <label class="col-xl-2 col-lg-2 col-md-3 col-form-label">
          {{ $t("grantedIps.route") }}
        </label>
        <div class="col-md-9 col-lg-10 col-xl-9">
          <div class="input-group">
            <b-form-input
              v-model="data.route"
              class="form-control"
              type="text"
              :class="!$v.data.route.required ? 'is-invalid' : 'is-valid'"
              @input="validateFields"
            />
            <div class="input-group-append">
              <span
                v-b-tooltip.top="$t('grantedIps.routeTooltip')"
                class="input-group-text"
              >
                <i class="fal fa-circle-question" />
              </span>
            </div>
            <b-form-invalid-feedback
              v-if="!$v.data.route.required"
              id="userid-feedback"
            >
              {{ $t("validation.required.route") }}
            </b-form-invalid-feedback>
          </div>
        </div>
      </div>

      <div class="form-group row align-items-center">
        <label class="col-xl-2 col-lg-2 col-md-3 col-form-label">
          {{ $t("grantedIps.description") }}
        </label>
        <div class="col-md-9 col-lg-10 col-xl-9">
          <input v-model="data.description" type="text" class="form-control" />
        </div>
      </div>

      <div class="form-group row align-items-center">
        <label class="col-xl-2 col-lg-2 col-md-3 col-form-label">
          {{ $t("general.active") }}
        </label>
        <div class="col-md-9 col-lg-10 col-xl-9">
          <span class="switch switch-sm">
            <label>
              <input v-model="data.active" type="checkbox" />
              <span></span>
            </label>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GrantedIps from "@/components/Admins/Settings/System/Security/GrantedIps/grantedIps";
import Users from "@/components/Admins/Account/Admin/admins";
import MultiIpInput from "@/components/Tools/MultiIpInput";

export default {
  components: {
    MultiIpInput
  },
  validations: {
    data: {
      user_id: {
        required: function (value) {
          if (this.data.public) return true;
          return value !== "";
        }
      },
      route: {
        required: function (value) {
          if (!this.data.public) return true;
          return value !== null && value.trim() !== "";
        }
      }
    }
  },
  data() {
    return {
      saveButtonEnabled: false,
      users: [],
      edit: false,
      isBusy: false,
      data: {
        user_id: "",
        public: false,
        route: "",
        ips: [],
        description: "",
        active: true
      },
      ipsValid: false
    };
  },
  watch: {
    "data.ips": {
      deep: true,
      handler: function () {
        this.validateFields();
      }
    },
    "data.public": function (value) {
      this.validateFields();
      if (value) {
        this.data.user_id = "";
      }
    }
  },
  mounted() {
    this.getUsers();

    const id = this.$route.params.id;
    this.edit = id !== undefined;
    if (this.edit) {
      this.getGrantedIp(id);
      return;
    }
    this.getCurrentIp();
  },
  methods: {
    getUsers() {
      const params = {
        page: 1,
        size: 100
      };
      Users.getAll(params)
        .then(response => {
          let users = response.data.data;
          users.unshift({
            name: this.$t("admin.allUsers"),
            id: "all"
          });
          this.users = users;
        })
        .catch(error => {
          console.log(error);
        });
    },
    getGrantedIp(id) {
      this.isBusy = true;
      GrantedIps.get(id)
        .then(response => {
          this.data = response.data.data;
          if (!this.data.public && this.data.user_id === null) {
            this.data.user_id = "all";
          }
          this.validateFields();
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.isBusy = false;
        });
    },
    validateFields() {
      this.$v.$touch();
      this.ipsValid = this.$refs.ipInput.isValid() || this.data.ips.length > 0;
      this.saveButtonEnabled = !this.$v.$invalid && this.ipsValid;
    },
    save() {
      this.isBusy = true;
      let saveData = this.data;

      if (!this.data.public && this.data.user_id === "all") {
        saveData.user_id = null;
      }

      saveData.ips = this.$refs.ipInput.getData();

      if (this.edit) {
        this.updateGrantedIp(saveData);
        return;
      }
      this.storeGrantedIp(saveData);
    },
    storeGrantedIp(data) {
      GrantedIps.store(data)
        .then(() => {
          this.$toast.fire({
            icon: "success",
            title: this.$t("grantedIps.grantedIpCreated")
          });
          this.cancel();
        })
        .catch(error => {
          this.$swal.fire({
            title: this.$t("general.caution"),
            text: error.response.data.message,
            icon: "error"
          });
          this.isBusy = false;
        })
        .finally(() => {
          this.isBusy = false;
        });
    },
    updateGrantedIp(data) {
      GrantedIps.update(this.data.id, data)
        .then(() => {
          this.$toast.fire({
            icon: "success",
            title: this.$t("grantedIps.grantedIpUpdated")
          });
          this.cancel();
        })
        .catch(error => {
          this.$swal.fire({
            title: this.$t("general.caution"),
            text: error.response.data.message,
            icon: "error"
          });
          this.isBusy = false;
        })
        .finally(() => {
          this.isBusy = false;
        });
    },
    cancel() {
      this.$router.push({ name: "adminSettingsSystemSecurityWhitelist" });
    },
    getCurrentIp() {
      fetch("https://api.ipify.org?format=json")
        .then(x => x.json())
        .then(({ ip }) => {
          this.data.ips.push(ip);
        });
    }
  }
};
</script>

<style scoped>
.text-invalid {
  font-size: 0.9rem;
  font-weight: 400;
  margin-top: 0.25rem;
  color: #f64e60;
}
</style>

<style lang="scss">
.input-group-append {
  .input-group-text {
    border-top-right-radius: 0.42rem !important;
    border-bottom-right-radius: 0.42rem !important;
    background: transparent;
  }
}
</style>
