import ApiService from "@/core/services/api.service";

ApiService.setHeader();
let url = process.env.VUE_APP_API_ADMIN;

class Queues {
  recreateConfig(data) {
    return ApiService.post(url + "/supervisor/recreateConfig", data);
  }
  start(data) {
    return ApiService.post(url + "/supervisor/start", data);
  }
  restart(data) {
    return ApiService.post(url + "/supervisor/restart", data);
  }
  stop(data) {
    return ApiService.post(url + "/supervisor/stop", data);
  }
  data() {
    return ApiService.get(url, "queues/data");
  }
}

export default new Queues();
