<template>
  <div>
    <div class="d-flex flex-row">
      <div
          id="kt_profile_aside"
          class="flex-row-auto offcanvas-mobile w-300px w-xl-350px"
      >
        <div class="card card-custom">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">{{ $t("settings.system") }}</h3>
            </div>
          </div>
          <div class="card-body pt-4">
            <div
                class="navi navi-bold navi-hover navi-active navi-link-rounded"
            >
              <div
                  v-for="(item, i) in tabNavigation"
                  :key="i"
                  class="navi-item mb-2"
              >
                <router-link
                    v-slot="{ href, navigate, isActive, isExactActive }"
                    :to="{ name: item.route }"
                    custom
                >
                  <a
                      :href="href"
                      class="navi-link py-4"
                      :class="{
                      active: isExactActive || $route.name.includes(item.route)
                    }"
                  >
                    <span v-if="item.icon" class="navi-icon">
                      <v-icon>{{ item.icon }}</v-icon>
                    </span>
                    <span class="navi-text">{{ $t(item.label) }}</span>
                  </a>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="flex-row-fluid ml-lg-8 client-details">
        <Languages v-if="$route.name === 'adminSettingsSystemLanguages'"/>
        <Countries v-else-if="$route.name === 'adminSettingsSystemCountries'"/>
        <LoginSecurity
            v-else-if="$route.name === 'adminSettingsSystemSecurity'"
        />
        <Queues v-else-if="$route.name === 'adminsSettingsSystemQueues'"/>
        <GrantedIpsTable
            v-else-if="$route.name === 'adminSettingsSystemSecurityWhitelist'"
        />
        <GrantedIpsEdit
            v-else-if="
            [
              'adminSettingsSystemSecurityWhitelistCreate',
              'adminSettingsSystemSecurityWhitelistEdit'
            ].includes($route.name)
          "
        />
      </div>
    </div>
  </div>
</template>

<style>
.disabledTab {
  display: none;
}
</style>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import Languages from "@/components/Admins/Settings/System/Languages/Index";
import Countries from "@/components/Admins/Settings/System/Countries/Index";
import Queues from "@/components/Admins/Settings/System/Queues/Index";
import LoginSecurity from "@/components/Admins/Settings/System/Security/LoginSecurity";
import GrantedIpsTable from "@/components/Admins/Settings/System/Security/GrantedIps/Table";
import GrantedIpsEdit from "@/components/Admins/Settings/System/Security/GrantedIps/Edit";

export default {
  name: "AdminsSettingsSystemIndex",
  components: {
    Languages,
    Countries,
    LoginSecurity,
    Queues,
    GrantedIpsTable,
    GrantedIpsEdit
  },
  data() {
    return {
      client: {},
      tabIndex: 0
    };
  },
  computed: {
    tabNavigation() {
      if (this.appVersion >= 2) {
        return [
          {
            route: "adminSettingsSystemSecurity",
            label: "settings.loginSecurity",
            icon: "fal fa-shield"
          },
          {
            route: "adminsSettingsSystemQueues",
            label: "settings.supervisorManagment",
            icon: "fal fa-layer-group"
          }
        ];
      }

      return [
        {
          route: "adminSettingsSystemLanguages",
          label: "settings.languages",
          icon: "fal fa-gear"
        },
        {
          route: "adminSettingsSystemCountries",
          label: "settings.countries",
          icon: "fal fa-earth-europe"
        },
        {
          route: "adminSettingsSystemSecurity",
          label: "settings.loginSecurity",
          icon: "fal fa-shield"
        },
        {
          route: "adminsSettingsSystemQueues",
          label: "settings.supervisorManagment",
          icon: "fal fa-layer-group"
        }
      ];
    }
  },
  mounted() {
    let defaultIndexRouteName = 'adminSettingsSystemLanguages';
    if (this.appVersion >= 2) {
      defaultIndexRouteName = 'adminSettingsSystemSecurity';
    }
    if (this.$route.name === "adminSettingsSystem") {
      this.$router.push({name: defaultIndexRouteName});
    }

    let payload = [
      {
        title: this.$t("menu.adminSettings"),
        route: ""
      },
      {
        title: this.$t("menu.adminSettingsSystem"),
        route: ""
      },
      {
        title: this.$t("settings.languages"),
        route: "/admin/settings/system/languages"
      },
      {
        title: this.$t("general.overview"),
        route: "/admin/settings/system/languages"
      },
      {
        title: this.$t("general.overview"),
        route: "/admin/settings/system/queues"
      }
    ];

    this.$store.dispatch(SET_BREADCRUMB, payload);
  },
  methods: {
    setActiveTab(event) {
      let target = event.target;
      if (!event.target.classList.contains("navi-link")) {
        target = event.target.closest(".navi-link");
      }

      const tab = target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".navi-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(target.getAttribute("data-tab"));

      // set current active tab
      target.classList.add("active");
    }
  }
};
</script>

<style lang="scss">
@import "../../../../../node_modules/bootstrap/scss/functions";
@import "../../../../../node_modules/bootstrap/scss/variables";
@import "../../../../../node_modules/bootstrap/scss/mixins";
</style>
