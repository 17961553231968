<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h5 class="font-weight-bold mb-6">
          {{ $t("countries.countryCreate") }}
        </h5>
      </div>
      <div class="card-toolbar">
        <button
          class="btn btn-secondary mr-2"
          @click="$emit('cancel-countries')"
        >
          {{ $t("general.cancel") }}
        </button>
        <button class="btn btn-primary" @click="storeCountry">
          {{ $t("general.save") }}
        </button>
      </div>
    </div>
    <v-progress-linear v-if="isBusy" indeterminate color="primary" />
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form">
      <!--begin::Body-->
      <div class="card-body">
        <div class="row">
          <label class="col-xl-3"></label>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("countries.name") }}
          </label>
          <div class="col-lg-9 col-xl-6">
            <input
              v-model="country.label_german"
              type="text"
              class="form-control"
            />
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("countries.nameInternational") }}
          </label>
          <div class="col-lg-9 col-xl-6">
            <input
              v-model="country.label_international"
              type="text"
              class="form-control"
            />
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("countries.iso") }}
          </label>
          <div class="col-lg-9 col-xl-6">
            <input v-model="country.iso" type="text" class="form-control" />
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("countries.euMember") }}
          </label>
          <div class="col-lg-9 col-xl-6">
            <span class="switch switch-sm">
              <label>
                <input v-model="country.eu_member" type="checkbox" />
                <span></span>
              </label>
            </span>
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("countries.disableTax") }}
          </label>
          <div class="col-lg-9 col-xl-6">
            <span class="switch switch-sm">
              <label>
                <input v-model="country.disable_tax" type="checkbox" />
                <span></span>
              </label>
            </span>
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("countries.tax") }}
          </label>
          <div class="col-lg-9 col-xl-6">
            <input
              v-model="country.tax"
              type="number"
              class="form-control"
              :disabled="country.disable_tax"
            />
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Countries from "@/components/Admins/Settings/System/Countries/countries";

export default {
  data() {
    return {
      country: {},
      isBusy: false
    };
  },
  methods: {
    storeCountry() {
      this.isBusy = true;
      const data = this.country;
      Countries.store(data)
        .then(() => {
          this.$toast.fire({
            icon: "success",
            title: this.$t("countries.countryCreated")
          });
          this.isBusy = false;
          this.$emit("cancel-countries");
        })
        .catch(error => {
          this.$swal.fire({
            title: this.$t("general.caution"),
            text: error.response.data.message,
            icon: "error"
          });
          this.isBusy = false;
        });
    }
  }
};
</script>
